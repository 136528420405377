import { OperationDocument } from "@domain/dto/operationDocument";
import { SureteDocument } from "@domain/dto/sureteDocument";
import { SureteDocumentStatus } from "@domain/enum/sureteDocumentStatus";
import { NemmoMimeType } from "@domain/enum/nemmoMimeType";
import { DocumentStatus } from "@domain/enum/documentStatus";
import { OperationDocumentWithPreviousVersions } from "@domain/dto/operationDocumentWithPreviousVersions";
import { Dictionary } from "lodash";
import { CreationDocument } from "@/domain/creationDocument";
import { UploadOperationDocumentMetadata } from "@/domain/uploadOperationDocumentMetadata";
import { SureteDocumentCategory } from "@domain/enum/sureteDocumentCategory";
import {
  doesExceedMaximumLength,
  NAME_MAX_LENGTH,
} from "@/utils/demandeCreationUtils";
import { AccordCommercialDocument } from "@domain/dto/accordCommercialDocument";
import { GedType } from "@domain/enum/gedType";
import { PoolAccordCommercialDocumentStatus } from "@domain/enum/poolAccordCommercialDocumentStatus";
import { Document } from "@domain/dto/document";
import { SocieteSupportDocument } from "@domain/dto/societeSupportDocument";
import { SuiviBudgetImportCreation } from "@/domain/suiviBudgetImportCreation";
import { UploadSuiviBudgetMetadata } from "@/domain/uploadSuiviBudgetMetadata";
import { AnnuaireUser } from "@domain/dto/annuaireUser";
import { Role } from "@domain/enum/role";

export function createOperationDocument(): OperationDocument {
  return {
    id: "",
    idDocument: "",
    idOperation: "",
    idUploader: "",
    firstNameUploader: "",
    lastNameUploader: "",
    name: "",
    category: "",
    size: 0,
    updatedAt: new Date(),
    createdAt: new Date(),
    version: 0,
    mimeType: NemmoMimeType.PDF,
    gedType: GedType.OPERATION,
  };
}

export function createSureteDocument(): SureteDocument {
  return {
    id: "",
    idSurete: "",
    idDocument: "",
    idSocieteSupport: "",
    name: "",
    size: 0,
    gedType: GedType.SURETE,
    firstNameUploader: "",
    lastNameUploader: "",
    idUploader: "",
    version: 0,
    mimeType: "",
    status: SureteDocumentStatus.SURETE,
    category: SureteDocumentCategory.SURETE,
  };
}

export function createCspDocument(): AccordCommercialDocument {
  return {
    createdAt: new Date(),
    documentStatus: PoolAccordCommercialDocumentStatus.PROJET,
    firstNameUploader: "",
    id: "",
    idPoolAccordCommercial: "",
    idDocument: "",
    idUploader: "",
    lastNameUploader: "",
    name: "",
    mimeType: NemmoMimeType.PDF,
    size: 0,
    version: 0,
    gedType: GedType.POOL_ACCORD_COMMERCIAL,
  };
}

// TODO remplacer par les vrais variants
export const DOCUMENT_CATEGORY_SORTING_ORDER = [
  "MATRISE_FONCIERE",
  "AUTORISATION_ADMINISTRATIVE",
  "COMMERCIALISATION",
  "FINANCIER",
  "TECHNIQUE",
  "JURIDIQUE",
  "AUTRE",
];

export const DOCUMENT_CATEGORY_NOT_SUGGESTED_IN_NEW_GED = [
  "ACTE",
  "PROJET",
  "LETTRE_ACCORD",
  "SUIVI_COMMERCIALISATION_DETAIL_LOTS",
];

export function shouldSuggestDocumentInGed(
  activeGedBanqueId: string | undefined,
  operationDocument: OperationDocument,
  documentsLatestVersion: OperationDocument[]
): boolean {
  const existingDocumentFilterCondition =
    activeGedBanqueId !== undefined
      ? !operationDocument.idBanque ||
        operationDocument.idBanque !== activeGedBanqueId
      : operationDocument.idBanque !== undefined;

  const isDocumentInAnotherGed = documentsLatestVersion
    .filter((document) => document.idBanque === activeGedBanqueId)
    .some(
      (document: OperationDocument) =>
        document.name === operationDocument.name &&
        document.category === operationDocument.category &&
        document.idBanque !== operationDocument.idBanque
    );

  return (
    existingDocumentFilterCondition &&
    !isDocumentInAnotherGed &&
    !isCertificateDocument(operationDocument) &&
    !DOCUMENT_CATEGORY_NOT_SUGGESTED_IN_NEW_GED.includes(
      operationDocument.category
    )
  );
}

export interface NameCategoryDocument {
  name: string;
  category: string;
}

export function validateDocumentList(
  documentList: NameCategoryDocument[],
  existingDocumentList: NameCategoryDocument[] = []
): boolean {
  return documentList.every((document) => {
    return (
      document.name.trim() !== "" &&
      !doesExceedMaximumLength(document.name, NAME_MAX_LENGTH) &&
      !containsForbiddenCharacters(document.name) &&
      document.category &&
      !containsDuplicateDocuments(document, existingDocumentList)
    );
  });
}

export function containsForbiddenCharacters(word: string): boolean {
  return word.includes("\\") || word.includes("/");
}

export function containsDuplicateDocuments(
  document: NameCategoryDocument,
  others: NameCategoryDocument[]
): boolean {
  return others.some((otherDocument) =>
    isDuplicateDocument(document, otherDocument)
  );
}

export function isDuplicateDocument(
  document1: NameCategoryDocument,
  document2: NameCategoryDocument
): boolean {
  return (
    document1.name.split(".")[0].trim() ===
      document2.name.split(".")[0].trim() &&
    document1.category.trim() === document2.category.trim()
  );
}

export function isDocx<T extends { mimeType?: string }>(document: T): boolean {
  return isMimeType(document, NemmoMimeType.DOCX);
}

export function isPDF<T extends { mimeType?: string }>(document: T): boolean {
  return isMimeType(document, NemmoMimeType.PDF);
}

export const mimeTypeExtensionMap: Record<NemmoMimeType, string> = {
  [NemmoMimeType.PDF]: "pdf",
  [NemmoMimeType.ZIP]: "zip",
  [NemmoMimeType.EXCEL]: "xlsx",
  [NemmoMimeType.DOCX]: "docx",
  [NemmoMimeType.DOC]: "doc",
  [NemmoMimeType.ODT]: "odt",
  [NemmoMimeType.PAGES]: "pages",
  [NemmoMimeType.XLS]: "xls",
  [NemmoMimeType.CSV]: "csv",
  [NemmoMimeType.PPT]: "ppt",
  [NemmoMimeType.PPTX]: "pptx",
  [NemmoMimeType.SVG]: "svg",
  [NemmoMimeType.PNG]: "png",
  [NemmoMimeType.GIF]: "gif",
  [NemmoMimeType.JPG]: "jpg",
  [NemmoMimeType.XML]: "xml",
  [NemmoMimeType.TXT]: "txt",
};

export function isMimeType<T extends { mimeType?: string }>(
  document: T,
  mimeType: NemmoMimeType
): boolean {
  const extension = mimeTypeExtensionMap[mimeType];
  return document.mimeType === mimeType || document.mimeType === extension;
}

export function isOperationDocument(
  document: Document
): document is OperationDocument {
  return (document as OperationDocument).idOperation !== undefined;
}
export function isSureteDocument(
  document: Document
): document is SureteDocument {
  return (document as SureteDocument).idSurete !== undefined;
}

export function isAccordCommercialDocument(
  document: Document
): document is AccordCommercialDocument {
  return (
    (document as AccordCommercialDocument).idPoolAccordCommercial !== undefined
  );
}

export function isSocieteSupportDocument(
  document: Document
): document is SocieteSupportDocument {
  return (document as SocieteSupportDocument).idSocieteSupport !== undefined;
}

export function isCertificateDocument(operationDocument: OperationDocument) {
  return (
    operationDocument.name.includes("_certificat_signature.pdf") ||
    operationDocument.documentStatus === DocumentStatus.CERTIFICATE
  );
}

interface CreationDocumentDico {
  [key: string]: CreationDocument;
}

export function documentMetadataListToMap(
  documentsToPost: CreationDocument[]
): CreationDocumentDico {
  const result: CreationDocumentDico = {};
  documentsToPost.forEach((document) => {
    result[document.file.name] = document;
  });

  return result;
}

export function createOperationDocumentUploadPayloadMetadata(
  documentList: CreationDocument[],
  gedBanqueId: string | undefined
): string {
  const metadata: UploadOperationDocumentMetadata = {};

  documentList.forEach((document) => {
    const name = document.file.name;
    metadata[name] = {
      category: document.category,
      newName: `${document.name}`,
      idConditionMiseEnPlace: document.idConditionMiseEnPlace,
      gedBanqueId,
    };
  });
  return JSON.stringify(metadata);
}

export function createSuiviBudgetDocumentUploadPayloadMetadata(
  creationRequest: SuiviBudgetImportCreation
): string {
  const metadata: UploadSuiviBudgetMetadata = {};
  creationRequest.documentList.forEach((document) => {
    const name = document.file.name;
    metadata[name] = {
      newName: `${document.newName}`,
      idBanque: creationRequest.idBanque,
      typeImport: document.typeImport,
      choixEuro: document.choixEuro,
    };
    if (document.nomTranche) {
      metadata[name].nomTranche = document.nomTranche;
    }
    if (document.sheetName) {
      metadata[name].sheetName = document.sheetName;
    }
  });
  return JSON.stringify(metadata);
}

export function buildOperationDocumentListGroupedByBanque(
  operationDocumentList: OperationDocumentWithPreviousVersions[],
  operationDemandeIdBanqueList: string[]
): Dictionary<OperationDocumentWithPreviousVersions[]> {
  const operationDocumentDictionary: Dictionary<
    OperationDocumentWithPreviousVersions[]
  > = {};

  const operationDocumentListSortedByIdBanque = [...operationDocumentList].sort(
    (document1, document2) => {
      if (!document1.idBanque) {
        return 1;
      } else if (!document2.idBanque) {
        return -1;
      }
      return 0;
    }
  );

  const operationDocumentListGroupedByBanque =
    operationDocumentListSortedByIdBanque.reduce(
      (accumulatedDictionary, operationDocument) => {
        const { idBanque } = operationDocument;
        if (idBanque) {
          accumulatedDictionary[idBanque] = [
            ...(accumulatedDictionary[idBanque] ?? []),
            { ...operationDocument },
          ];
        } else {
          accumulatedDictionary["unshared"] = [
            ...(accumulatedDictionary["unshared"] ?? []),
            { ...operationDocument },
          ];
        }
        return accumulatedDictionary;
      },
      operationDocumentDictionary
    );

  for (const idBanque of operationDemandeIdBanqueList) {
    if (!operationDocumentListGroupedByBanque[idBanque]) {
      operationDocumentListGroupedByBanque[idBanque] = [];
    }
  }

  return operationDocumentListGroupedByBanque;
}

export function formatFileSize(fileSize: number): string {
  if (fileSize < 1000000) {
    return (fileSize / 1024).toFixed(1).toString().replace(".", ",") + " Ko";
  } else {
    return (
      (fileSize / (1024 * 1024)).toFixed(1).toString().replace(".", ",") + " Mo"
    );
  }
}

export function buildEditorListLabel(
  userList: { firstName: string; lastName: string }[]
): string {
  return `${userList
    .map((user) => `${user.firstName} ${user.lastName}`)
    .join(", ")}`;
}

export function getDateTime(date?: Date) {
  return date ? date.getTime() : 0;
}

export function computeAnnuaireUserName(user: AnnuaireUser): string {
  if (user.roles.includes(Role.GROUP_ADMIN)) {
    return `${user.firstName} ${user.lastName} - administrateur`;
  }
  return `${user.firstName} ${user.lastName}`;
}
